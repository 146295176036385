import styled, { css } from 'styled-components';
import SearchCourse from 'Components/Search/Course';
import { Input, Container } from 'Components/Search/Search.styled';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        background-color: var(--bg-color, ${theme.colors.primary.hex});
        height: auto;
        align-content: center;

        @media ${theme.breakpoints.medium.media} {
            padding-top: 0;
        }

        @media ${theme.breakpoints.large.media} {
            padding: 1.75rem 0;
        }
    `}
`;

export const SearchContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        position: relative;
        margin: 1.5rem 0 1rem;

        @media ${theme.breakpoints.medium.media} {
            margin-bottom: 2rem;
        }

        @media ${theme.breakpoints.large.media} {
            margin: 0;
        }
    `}
`;

export const ButtonWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        padding: 1rem 0 1.7rem;
        justify-content: center;
        align-items: center;

        a {
            font-weight: bold;
        }

        @media ${theme.breakpoints.medium.media} {
            padding: 0;

            a {
                font-weight: 300;
            }
        }
    `}
`;

export const StyledSearchCourse = styled(SearchCourse)`
    ${({ theme }) => css`
        @media ${theme.breakpoints.large.media} {
            ${Input} {
                &::placeholder {
                    font-size: 1.125rem;
                }
            }
        }

        @media ${theme.breakpoints.large.media} {
            ${Input} {
                &::placeholder {
                    font-size: 1.6rem;
                }
            }
            ${Container} {
                max-width: 100%;
            }
        }
    `}
`;
