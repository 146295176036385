import React from 'react';
import { useTheme } from 'styled-components';
import useBreakpoint from 'Common/hooks/useBreakpoint';

import Main from 'Elements/Main';
import Section from 'Elements/Section';
import ImageLink from 'Components/Card/Link/ImageLink';

import Grid, { Col } from 'Components/Grid';
import QuickLinks, { Props as QuickLinksProps } from 'Components/QuickLinks';
import TefCard from 'Components/Card/Tef';
import IconCard from 'Components/Card/Icon';
import Arrow from 'Components/Card/Link/Arrow';
import CardGrid from 'Components/Grid/CardGrid';

import HeroBanner, { Props as HeroBannerProps } from 'Modules/HeroBanner/Homepage';
import { HomepageCardGridProps } from 'Common/util/cardGrid';

import KeyStats, { Props as KeyStatsProps } from 'Modules/KeyStats';
import BENMiniListing, { Props as BenMiniListingProps } from 'Modules/MiniListing/Ben';
import VirtualTour, { Props as VirtualTourProps } from 'Modules/VirtualTour';
import FooterVisit, { FooterVisitProps } from 'Modules/FooterVisit';

export interface Props {
    heroBanner: HeroBannerProps;
    quickLinks: QuickLinksProps;
    homepageGrid: HomepageCardGridProps;
    keyStats: KeyStatsProps;
    footerVisit: FooterVisitProps;
    news: BenMiniListingProps;
    events: BenMiniListingProps;
    displayVirtualTour: boolean;
    virtualTour: VirtualTourProps;
}

const Homepage: React.FC<Props> = ({
    heroBanner,
    quickLinks,
    keyStats,
    footerVisit,
    news,
    events,
    displayVirtualTour,
    virtualTour,
    homepageGrid
}) => {
    const { breakpoints } = useTheme();
    const isMediumBreakpoint = useBreakpoint(breakpoints.medium.value);

    const { quadRow, gridNavigation } = homepageGrid;

    return (
        <Main>
            <Section id="heroBanner" paddingTop={0} paddingBottom={0}>
                <HeroBanner {...heroBanner} />
            </Section>
            <Section id="quickLinksCTA" paddingTop={0} paddingBottom={0}>
                <QuickLinks {...quickLinks} />
            </Section>
            <Section id="gridLink" background paddingTop={1}>
                <Grid>
                    <Col>
                        <CardGrid.Layout
                            pattern={{
                                areas: CardGrid.Pattern.quadRow,
                                components: [
                                    <IconCard
                                        {...quadRow.iconCards.cardOne}
                                        link={{
                                            ...quadRow.iconCards.cardOne.link,
                                            variant: 'secondaryPrimary'
                                        }}
                                        variant="tertiaryBasePrimaryHover"
                                    />,
                                    <TefCard {...quadRow.tefCard} />,
                                    <IconCard {...quadRow.iconCards.cardTwo} />,
                                    <IconCard {...quadRow.iconCards.cardThree} />
                                ]
                            }}
                            rows={{ b: 1, m: 2 }}
                        />
                        <div style={{ marginTop: '1rem' }}>
                            <CardGrid.Layout
                                rows={{ b: 1, m: 4 }}
                                pattern={{
                                    areas: CardGrid.Pattern.gridNavigation,
                                    components: [
                                        <Arrow
                                            {...gridNavigation.linkButtons.cardOne}
                                            variant="text"
                                            colors={{ hoverLinkVariant: 'secondaryPrimary' }}
                                        />,
                                        <ImageLink
                                            {...gridNavigation.imageLinks.cardOne}
                                            link={{
                                                ...gridNavigation.imageLinks.cardOne.link,
                                                variant: 'text'
                                            }}
                                            colors={{ hoverLinkVariant: 'secondaryPrimary' }}
                                        />,
                                        <Arrow
                                            {...gridNavigation.linkButtons.cardTwo}
                                            variant="text"
                                            colors={{ hoverLinkVariant: 'secondaryPrimary' }}
                                        />,
                                        <ImageLink
                                            {...gridNavigation.imageLinks.cardTwo}
                                            link={{
                                                ...gridNavigation.imageLinks.cardTwo.link,
                                                variant: 'text'
                                            }}
                                            colors={{ hoverLinkVariant: 'secondaryPrimary' }}
                                        />,
                                        <ImageLink
                                            {...gridNavigation.imageLinks.cardThree}
                                            link={{
                                                ...gridNavigation.imageLinks.cardThree.link,
                                                variant: 'text'
                                            }}
                                            colors={{ hoverLinkVariant: 'secondaryPrimary' }}
                                        />,
                                        <Arrow
                                            {...gridNavigation.linkButtons.cardThree}
                                            variant="text"
                                            colors={{ hoverLinkVariant: 'secondaryPrimary' }}
                                        />,
                                        <Arrow
                                            {...gridNavigation.linkButtons.cardFour}
                                            variant="text"
                                            colors={{ hoverLinkVariant: 'secondaryPrimary' }}
                                        />
                                    ]
                                }}
                            />
                        </div>
                    </Col>
                </Grid>
            </Section>
            <Section
                id="keyStats"
                paddingTop={isMediumBreakpoint ? 1 : 0}
                paddingBottom={!isMediumBreakpoint ? 2 : 1}
            >
                <Grid>
                    <Col>
                        <KeyStats {...keyStats} />
                    </Col>
                </Grid>
            </Section>
            {displayVirtualTour && (
                <Section id="homepageMap" paddingBottom={0} paddingTop={isMediumBreakpoint ? 1 : 0}>
                    <VirtualTour {...virtualTour} />
                </Section>
            )}
            <BENMiniListing {...news} />
            <BENMiniListing {...events} />
            <Section id="footerVisit" paddingTop={3} paddingBottom={0}>
                <FooterVisit {...footerVisit} />
            </Section>
        </Main>
    );
};

export default Homepage;
