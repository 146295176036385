import React, { useState } from 'react';

import Image, { StaticImageData } from 'next/image';

import { Container, Wrapper, Card, Heading, ImageWrapper, HiddenLink } from './Tef.styled';
import { LinkProps } from 'Elements/Link';
import Arrow from 'Elements/Link/Arrow';

export interface Props {
    heading: string;
    headingType?: 'h2' | 'h3';
    text: string;
    image: {
        src: StaticImageData | string;
        alt?: string;
    };
    link: LinkProps;
    colors?: {
        background?: React.CSSProperties['color'];
        backgroundHover?: React.CSSProperties['color'];
    };
}

const Tef: React.FC<Props> = ({ headingType = 'h2', heading, text, image, link, colors }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Wrapper>
            <Card
                $isHovered={isHovered}
                style={
                    {
                        '--tef-bg': colors?.background,
                        '--tef-bg-hover': colors?.backgroundHover
                    } as React.CSSProperties
                }
            >
                <Container>
                    <Heading as={headingType}>{heading}</Heading>
                    <p>{text}</p>
                    <Arrow
                        {...link}
                        iconOutline={!isHovered}
                        isHovered={isHovered}
                        data-hovered={isHovered}
                        variant="text"
                        style={{ marginTop: 'auto' }}
                    />
                </Container>
                <ImageWrapper
                    $imageSrc={typeof image.src === 'string' ? image.src : image.src.src}
                    $isHovered={isHovered}
                >
                    <Image
                        src={image.src}
                        alt={image.alt || ''}
                        style={{ objectFit: 'cover', zIndex: '1' }}
                        fill
                    />
                </ImageWrapper>
                <HiddenLink
                    href={link.href}
                    aria-label={link.ariaLabel}
                    aria-hidden="true"
                    tabIndex={-1}
                    data-hovered={isHovered}
                    onMouseOver={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                />
            </Card>
        </Wrapper>
    );
};

export default Tef;
