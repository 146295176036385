import styled, { css } from 'styled-components';
import NextLink from 'next/link';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        padding-bottom: 2.2rem;
        height: 100%;

        @media ${theme.breakpoints.medium.media} {
            padding: unset;
        }
    `}
`;

export const Card = styled.article<{
    $isHovered: boolean;
}>`
    ${({ theme, $isHovered }) => css`
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        border-radius: 0.5rem;
        padding: 1rem 1.5rem;
        background-color: var(--tef-bg, ${theme.colors.primaryContrast.hex});
        transition: background-color 0.25s ease;

        ${$isHovered &&
        css`
            background-color: var(--tef-bg-hover, ${theme.colors.accents[3].hex});
        `}

        @media ${theme.breakpoints.medium.media} {
            padding: 2rem 1.5rem 1rem;
        }

        @media ${theme.breakpoints.large.media} {
            padding: 2rem 3.75rem 1.5rem 2rem;
        }
    `}
`;

export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        height: 100%;

        @media ${theme.breakpoints.large.media} {
            max-width: 25rem;
        }
    `}
`;

export const Heading = styled.h2`
    ${({ theme }) => css`
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1.2;
        margin-top: 0;

        @media ${theme.breakpoints.medium.media} {
            font-size: 2.5rem;
        }
    `}
`;

export const ImageWrapper = styled.div<{ $imageSrc: string; $isHovered: boolean }>`
    ${({ theme, $imageSrc, $isHovered }) => css`
        position: absolute;
        bottom: -2.75rem;
        right: 1.375rem;
        width: 5.5rem;
        height: 5.5rem;
        min-width: 5.5rem;
        pointer-events: none;

        @media ${theme.breakpoints.medium.media} {
            position: relative;
            bottom: unset;
            right: unset;
        }

        @media ${theme.breakpoints.large.media} {
            margin-left: 2.4rem;
            min-width: 7.5rem;
            width: 7.5rem;
            height: 7.5rem;

            &:before,
            &:after {
                content: '';
                position: absolute;
                width: 5.5rem;
                height: 5.5rem;
                background-image: url(${$imageSrc});
                background-size: cover;
                background-position: center;
                transition: transform 0.25s ease-out, opacity 0.25s ease-out;
                opacity: 0;

                @media ${theme.breakpoints.large.media} {
                    width: 7.5rem;
                    height: 7.5rem;
                }
            }

            ${$isHovered &&
            css`
                &:before {
                    transform: translate(1rem, 0.65rem) rotate(11deg);
                    opacity: 1;
                }

                &:after {
                    transform: translate(-1rem, 0.65rem) rotate(-11deg);
                    opacity: 1;
                }
            `}
        }
    `}
`;

export const HiddenLink = styled(NextLink)`
    position: absolute;
    inset: 0;
`;
