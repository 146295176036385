import styled, { css, FlattenSimpleInterpolation, DefaultTheme } from 'styled-components';
import { useMemo } from 'react';
import { GridAreaProps, Responsive } from '.';

//FlattenSimpleInterpolation - type that represents a chunk of CSS css`grid-area: '1 / 1 / 5 / 5'`
type StyleCallback<Style> = (value: Style) => FlattenSimpleInterpolation;

const createResponsiveStyle = <T extends string | number>(
    theme: DefaultTheme,
    prop: Responsive<T>,
    styleCallback: StyleCallback<T>
) => {
    //optimise re-renders if values dont change
    return useMemo(() => {
        return Object.entries(prop).map(([breakpointKey, value]) => {
            const bp = Object.keys(theme.breakpoints).find(
                key => key === breakpointKey || theme.breakpoints[key].shortName === breakpointKey
            );
            if (bp) {
                return css`
                    @media ${theme.breakpoints[bp].media} {
                        ${styleCallback(value)}
                    }
                `;
            }
        });
    }, [theme, prop, styleCallback]);
};

export const GridContainer = styled.div<{
    $rows: Responsive<number>;
    $rowGap?: Responsive<string>;
    $columns?: Responsive<number>;
    $columnGap?: Responsive<string>;
}>`
    ${({ theme, $rows, $columns, $rowGap, $columnGap }) => css`
        display: grid;
        width: 100%;

        ${typeof $rows === 'number'
            ? css`
                  grid-template-rows: repeat(${$rows}, 1fr);
              `
            : createResponsiveStyle(
                  theme,
                  $rows,
                  rows => css`
                      grid-template-rows: repeat(${rows}, 1fr);
                  `
              )}

        ${typeof $columns === 'number'
            ? css`
                  grid-template-columns: repeat(${$columns}, 1fr);
              `
            : $columns &&
              createResponsiveStyle(
                  theme,
                  $columns,
                  columns => css`
                      grid-template-columns: ${columns};
                  `
              )}

        ${typeof $columnGap === 'string'
            ? css`
                  column-gap: ${$columnGap};
              `
            : $columnGap &&
              createResponsiveStyle(
                  theme,
                  $columnGap,
                  columnGap => css`
                      column-gap: ${columnGap};
                  `
              )}

        ${typeof $rowGap === 'string'
            ? css`
                  row-gap: ${$rowGap};
              `
            : $rowGap &&
              createResponsiveStyle(
                  theme,
                  $rowGap,
                  rowGap => css`
                      row-gap: ${rowGap};
                  `
              )}
    `}
`;

export const GridItem = styled.div<{ $area: GridAreaProps['area'] }>`
    ${({ theme, $area }) => css`
        ${$area === 'string'
            ? css`
                  grid-area: ${$area};
              `
            : createResponsiveStyle(
                  theme,
                  $area,
                  area => css`
                      grid-area: ${area};
                  `
              )}
    `}
`;
