import type { Entry, PageEntry } from 'contensis-delivery-api';
import HTMLReactParser from 'html-react-parser';

import cmsParser from 'Common/util/cmsParser';
import cardGrid from 'Common/util/cardGrid';
import { searchCourseFetchResults } from 'Common/func/courseSearchBox';

import type { Props } from './Homepage';

export type PageData = {
    entry: PageEntry;
    news: Entry[];
    events: Entry[];
    courses: Entry[];
};

const datamap = (data: PageData): Props => {
    const { entry, news, events, courses } = data;

    const searchCourseObject = {
        search: {
            href: '/courses?search={{search}}',
            ariaLabel: 'Submit your course search'
        },
        label: 'Course search',
        fetchResults: (value: string): { id: string; label: string; href: string }[] =>
            searchCourseFetchResults(courses, value)
    };

    return {
        heroBanner: {
            heading: entry.flexibleHeroBannerBuilder.heading,
            button:
                entry.flexibleHeroBannerBuilder.buttonComposer.length >= 1
                    ? cmsParser.linkButton(entry.flexibleHeroBannerBuilder.buttonComposer[0].value)
                    : undefined,
            postHeading:
                entry.flexibleHeroBannerBuilder.subheading &&
                entry.flexibleHeroBannerBuilder.subheading,
            imageThemeBuilder: entry.flexibleHeroBannerBuilder.imageAndThemeBuilder.map(item => ({
                desktop: cmsParser.image(item.desktopBackgroundImage),
                mobile: cmsParser.image(item.mobileBackgroundImage),
                textColor: item.textColour && cmsParser.getThemeColour(item.textColour),
                searchColor: item.searchColour && cmsParser.getThemeColour(item.searchColour),
                buttonVariant: item.buttonVariant && item.buttonVariant
            }))
        },
        quickLinks: {
            link: cmsParser.link(entry.quickLink),
            searchCourse: searchCourseObject
        },
        homepageGrid: cardGrid.Homepage(entry.cardGrid),
        keyStats: {
            keyStats: entry.keyFacts.map(({ number, fact, description }, index) => ({
                id: index,
                statValue: number,
                stat: fact.replace('{{', '{{{').replace('}}', '}}}'),
                description
            })),
            link: cmsParser.linkButton(entry.keyFactsLink),
            heading: entry.keyFactsHeading,
            headingType: 'h3'
        },
        displayVirtualTour: entry.displayVirtualTour,
        virtualTour: {
            heading: entry.virtualTourLabel,
            text: entry.virtualTourCopyText,
            fileSize: entry.virtualTourSize,
            button: {
                ariaLabel: entry.virtualTourAriaLabel,
                label: entry.virtualTourLabel
            },
            embed: {
                title: entry.virtualTourLabel,
                src: entry.virtualTourEmbed
            }
        },
        news: {
            id: 'news',
            heading: 'Featured news and blogs',
            moreLink: {
                href: '/media-hub',
                label: 'Visit media hub',
                ariaLabel: 'Visit meadia hub'
            },
            items: news.map(item => cmsParser.benCard(item)),
            paddingTop: true,
            showBackground: false
        },
        events: {
            id: 'events',
            heading: 'Upcoming events',
            moreLink: {
                href: '/media-hub/events',
                label: 'View all events',
                ariaLabel: 'View all event'
            },
            items: events.map(item => cmsParser.benCard(item)),
            showBackground: false
        },
        footerVisit: {
            heading: entry.falseFooter.heading,
            children: HTMLReactParser(entry.falseFooter.copyText),
            link: cmsParser.link(entry.falseFooter.link),
            video: cmsParser.transcriptEmbed(entry.falseFooter.videoTranscript),
            videoButton: {
                label: 'Southampton',
                ariaLabel: 'Watch a video about Southampton'
            }
        }
    };
};

export default datamap;
