import cmsParser from 'Common/util/cmsParser';
import dynamic from 'next/dynamic';
import { IconCardProps } from 'Components/Card/Icon';
import { ImageLinkProps } from 'Components/Card/Link/ImageLink';
import { ArrowLinkProps } from 'Components/Card/Link/Arrow';
import { TefCardProps } from 'Components/Card/Tef';

const createIconCard = (data: any, colors?: IconCardProps['colors']): IconCardProps => {
    return {
        heading: data.heading,
        icon: dynamic(() =>
            import('@solent-university/solent-icons').then(icon => icon[data.icon.name])
        ),
        text: data.text,
        link: cmsParser.link(data.link),
        colors: {
            hoverLinkVariant: colors?.hoverLinkVariant,
            background: colors?.background,
            backgroundHover: colors?.backgroundHover,
            color: colors?.color,
            colorHover: colors?.colorHover
        }
    };
};

const createImageLink = (data: any, colors?: ImageLinkProps['colors']): ImageLinkProps => {
    return {
        image: cmsParser.image(data.image),
        link: cmsParser.link(data.link),
        colors: {
            borderColor: colors?.borderColor,
            borderColorHover: colors?.borderColorHover,
            hoverLinkVariant: colors?.hoverLinkVariant
        }
    };
};

export interface HomepageCardGridProps {
    quadRow: {
        tefCard: TefCardProps;
        iconCards: {
            cardOne: IconCardProps;
            cardTwo: IconCardProps;
            cardThree: IconCardProps;
        };
    };
    gridNavigation: {
        imageLinks: {
            cardOne: ImageLinkProps;
            cardTwo: ImageLinkProps;
            cardThree: ImageLinkProps;
        };
        linkButtons: {
            cardOne: ArrowLinkProps;
            cardTwo: ArrowLinkProps;
            cardThree: ArrowLinkProps;
            cardFour: ArrowLinkProps;
        };
    };
}

class CardGrid {
    static Homepage = (data: any): HomepageCardGridProps => {
        return {
            quadRow: {
                tefCard: {
                    image: cmsParser.image(data.tefGridCard.image),
                    heading: data.tefGridCard.heading,
                    text: data.tefGridCard.text,
                    link: cmsParser.link(data.tefGridCard.link)
                },
                iconCards: {
                    cardOne: createIconCard(data.iconCardOne, {
                        hoverLinkVariant: 'secondaryPrimary'
                    }),
                    cardTwo: createIconCard(data.iconCardTwo, {
                        hoverLinkVariant: 'secondaryPrimary'
                    }),
                    cardThree: createIconCard(data.iconCardThree, {
                        hoverLinkVariant: 'secondaryPrimary'
                    })
                }
            },
            gridNavigation: {
                imageLinks: {
                    cardOne: createImageLink(data.imageLinkFeatured),
                    cardTwo: createImageLink(data.imageLinkLeft),
                    cardThree: createImageLink(data.imageLinkRight)
                },
                linkButtons: {
                    cardOne: cmsParser.arrowLink(data.linkTopLeft),
                    cardTwo: cmsParser.arrowLink(data.linkTopRight),
                    cardThree: cmsParser.arrowLink(data.linkBottomLeft),
                    cardFour: cmsParser.arrowLink(data.linkBottomRight)
                }
            }
        };
    };
}

export default CardGrid;
