import React, { useState } from 'react';
import Image, { StaticImageData } from 'next/image';
import { Wrapper, Container, ImageWrapper, StyledArrow, HiddenLink } from './ImageLink.styled';
import { ArrowLinkProps } from 'Elements/Link/Arrow';
import { LinkVariants } from 'Theme/types/elements.type';

type Colors = {
    borderColor?: React.CSSProperties['color'];
    borderColorHover?: React.CSSProperties['color'];
    hoverLinkVariant?: LinkVariants;
};

export interface Props {
    image: {
        src: string | StaticImageData;
        alt?: string;
    };
    link: ArrowLinkProps;
    colors?: Colors;
    featured?: boolean;
}

const Link: React.FC<Props> = ({ image, link, colors, featured = false }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Wrapper
            style={
                {
                    '--border-color': colors?.borderColor && colors.borderColor,
                    '--border-color-hover': colors?.borderColorHover && colors.borderColorHover
                } as React.CSSProperties
            }
        >
            <Container $isHovered={isHovered} $featured={featured}>
                <ImageWrapper>
                    <Image
                        {...image}
                        alt={image.alt ? image.alt : ''}
                        fill
                        style={{ objectFit: 'cover', scale: isHovered ? '1.05' : '1' }}
                    />
                </ImageWrapper>
            </Container>
            <StyledArrow
                {...link}
                $isHovered={isHovered}
                isHovered={isHovered}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                iconOutline={!isHovered}
                variant={isHovered ? colors?.hoverLinkVariant : link.variant}
            />
            <HiddenLink
                href={link.href}
                aria-label={link.ariaLabel}
                aria-hidden="true"
                tabIndex={-1}
                data-hovered={isHovered}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />
        </Wrapper>
    );
};

export default Link;
