import styled, { css } from 'styled-components';
import NextImage from 'next/image';

export const DesktopNextImage = styled(NextImage)`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.mediumSmall.media} {
            display: block;
        }
    `}
`;
export const MobileNextImage = styled(NextImage)`
    ${({ theme }) => css`
        display: block;

        @media ${theme.breakpoints.mediumSmall.media} {
            display: none;
        }
    `}
`;

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
`;

export const Container = styled.figure`
    ${({ theme }) => css`
        position: relative;
        width: 100%;
        min-height: 20rem;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0;
        margin: 0;
        padding-bottom: 4rem;

        .next-image {
            object-position: 60% 50%;
        }

        @media ${theme.breakpoints.medium.media} {
            padding-bottom: 3rem;
            min-height: 22;

            .next-image {
                object-position: 50% 20%;
            }
        }

        @media ${theme.breakpoints.xlarge.media} {
            min-height: 35rem;
        }

        @media ${theme.breakpoints.colossal.media} {
            .next-image {
                object-position: 50% 15%;
            }
        }
    `}
`;

export const Heading = styled.h1`
    ${({ theme }) => css`
        font-size: 2.5rem;
        position: relative;
        letter-spacing: 0;
        line-height: 1;
        color: var(--text-color, ${theme.colors.text.hex});
        margin-top: 4rem;
        text-wrap: balance;

        @media ${theme.breakpoints.smallLarge.media} {
            font-size: 3.2rem;
        }

        @media ${theme.breakpoints.medium.media} {
            margin-top: 2.5rem;
            margin-bottom: 2rem;
            font-size: 5rem;
            line-height: 4.5rem;
        }

        @media ${theme.breakpoints.xlarge.media} {
            font-size: 6rem;
            line-height: 6.25rem;
            margin-top: 8.25rem;
        }
    `}
`;

export const PostHeading = styled.span`
    ${({ theme }) => css`
        font-family: ${theme.fonts.secondary};
        display: block;
        font-weight: 300;
        line-height: 1.2;
        font-size: 1.5rem;
        padding-top: 2rem;
        text-wrap: inherit;

        @media ${theme.breakpoints.large.media} {
            padding-top: 0;
            margin-top: 2rem;
            font-size: 2.25rem;
        }
    `}
`;
