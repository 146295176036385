import styled, { css } from 'styled-components';
import NextLink from 'next/link';

export const Wrapper = styled.article<{ $isHovered: boolean; $variant?: string }>`
    ${({ theme, $isHovered, $variant }) => css`
        position: relative;
        color: var(
            --color,
            ${$variant ? theme.components.iconCard[$variant].color : theme.colors.text.hex}
        );
        background-color: var(
            --bg,
            ${$variant
                ? theme.components.iconCard[$variant].background
                : theme.colors.primaryContrast.hex}
        );
        border-radius: 0.5rem;
        padding: 1.5rem 1.2rem;
        transition: background-color, color, 0.25s ease;
        height: 100%;

        ${StyledIcon} {
            color: var(
                --color,
                ${$variant ? theme.components.iconCard[$variant].color : theme.colors.text.hex}
            );
        }

        ${$isHovered &&
        css`
            color: var(
                --color-hover,
                ${$variant
                    ? theme.components.iconCard[$variant].colorHover
                    : theme.colors.primaryContrast.hex}
            );
            background-color: var(
                --bg-hover,
                ${$variant
                    ? theme.components.iconCard[$variant].backgroundHover
                    : theme.colors.primary.hex}
            );

            ${StyledIcon} {
                color: var(
                    --color-hover,
                    ${$variant
                        ? theme.components.iconCard[$variant].colorHover
                        : theme.colors.primaryContrast.hex}
                );
            }
        `}

        @media ${theme.breakpoints.medium.media} {
            padding: 1rem;
        }

        @media ${theme.breakpoints.large.media} {
            padding: 1.5rem 0.75rem;
        }
    `}
`;

export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 100%;

        & a {
            margin-top: auto;
        }

        @media ${theme.breakpoints.medium.media} {
            text-align: center;
            align-items: center;
        }
    `}
`;

export const Heading = styled.h2`
    ${({ theme }) => css`
        display: block;
        font-size: 1.5rem;
        line-height: 1.2;

        @media ${theme.breakpoints.medium.media} {
            text-align: center;
            margin-bottom: 2rem;
        }
    `}
`;

export const HiddenLink = styled(NextLink)`
    position: absolute;
    inset: 0;
`;

export const StyledIcon = styled.svg`
    height: 3.5rem;
    width: 3.5rem;
`;

export const Text = styled.p`
    margin-bottom: 1.25rem;
`;
