import styled, { css } from 'styled-components';
import NextLink from 'next/link';

export const Wrapper = styled.div<{ $isHovered: boolean }>`
    ${({ theme, $isHovered }) => css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-radius: 0.5rem;
        transform: translateZ(0);
        transition: background 0.25s ease, transform 0.25s ease;
        background: var(--bg-color, ${theme.colors.primaryContrast.hex});
        min-height: 7rem;

        ${$isHovered &&
        css`
            background: var(--bg-color-hover, ${theme.colors.primary.hex});
            transform: scale(0.98);

            //tried using not selector, wouldnt work for some reason
            & > * {
                transform: scale(1.02);
            }
        `}

        @media ${theme.breakpoints.large.media} {
            min-height: 11rem;
        }
    `}
`;

export const HiddenLink = styled(NextLink)`
    position: absolute;
    inset: 0;
`;
