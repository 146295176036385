// grid-area: grid-row-start / grid-col-start / grid-row-end / grid-col-end
import { Pattern } from './CardGrid';
export type PatternName = 'quadRow' | 'gridNavigation';

export const PatternAreas: Record<PatternName, Pattern['areas']> = {
    quadRow: {
        cardOne: {
            b: '1 / 1 / 1 / 13',
            m: '1 / 1 / 1 / 5',
            xl: '1 / 1 / 5 / 3'
        },
        cardTwo: {
            b: '2 / 1 / 2 / 13',
            m: '1 / 5 / 1 / 13',
            xl: '1 / 3 / 5 / 9'
        },
        cardThree: {
            b: '3 / 1 / 3 / 13',
            m: '2 / 1 / 2 / 7',
            xl: '1 / 9 / 5 / 11'
        },
        cardFour: {
            b: '4 / 1 / 4 / 13',
            m: '2 / 7 / 2/ 13',
            xl: '1 / 11 / 5 / 13'
        }
    },

    gridNavigation: {
        cardOne: {
            b: '1 / 1 / 2 / 13',
            m: '1 / 1 / 7 / 5'
        },
        cardTwo: {
            b: '4 / 1 / 5 / 13',
            m: '1 / 5 / 12 / 9'
        },
        cardThree: {
            b: '5 / 1 / 6 / 13',
            m: '1 / 9 / 7 / 13'
        },
        cardFour: {
            b: '2 / 1 / 3 / 13',
            m: '7 / 1 / 10 / 5'
        },
        cardFive: {
            b: '6 / 1 / 7 / 13',
            m: '7 / 9 / 10 / 13'
        },
        cardSix: {
            b: '3 / 1 / 4 / 13',
            m: '10 / 1 / 12 / 5'
        },
        cardSeven: {
            b: '7 / 1 / 8 / 13',
            m: '10 / 9 / 12 / 13'
        }
    }
};

export default PatternAreas;
