import React from 'react';
import { BreakpointAllNames } from 'Theme/types/breakpoints.type';
import { GridContainer, GridItem } from './CardGrid.styled';
import PatternAreas, { PatternName } from './CardGrid.pattern';

export type Responsive<Type> = Type | Partial<Record<BreakpointAllNames, Type>>;

export interface GridAreaProps {
    area: Responsive<string>;
    children: React.ReactNode;
}

export interface Props {
    rows?: Responsive<number>;
    rowGap?: Responsive<string>;
    columns?: Responsive<number>;
    columnGap?: Responsive<string>;
    children: React.ReactNode;
}

export interface Pattern {
    areas: {
        [key: string]: {
            [key in BreakpointAllNames]?: string;
        };
    };
    components: React.ReactNode[];
}

export interface LayoutProps {
    pattern: Pattern;
    gap?: Responsive<string>;
    rows?: Responsive<number>;
    columns?: Responsive<number>;
}

const Layout = ({ pattern, gap = '1rem', rows = 10, columns = 12 }: LayoutProps) => {
    //optimise re-renders if pattern object values don't change
    const memoizedPattern = React.useMemo(() => pattern, [pattern]);
    return (
        <GridContainer $rows={rows} $rowGap={gap} $columns={columns} $columnGap={gap}>
            {Object.entries(pattern.areas).map(([key, area], index) => (
                <GridItem key={`layout-${key}-${index}`} $area={area}>
                    {memoizedPattern.components[index]}
                </GridItem>
            ))}
        </GridContainer>
    );
};

const CardGrid: React.FC<Props> & {
    GridArea: React.FC<GridAreaProps>;
    Layout: React.FC<LayoutProps>;
    Pattern: Record<PatternName, Pattern['areas']>;
} = ({ rows = 10, rowGap = '1rem', columns = 12, columnGap = '1rem', children }) => {
    return (
        <GridContainer
            data-testid="card-grid"
            $rows={rows}
            $rowGap={rowGap}
            $columns={columns}
            $columnGap={columnGap}
        >
            {children}
        </GridContainer>
    );
};

const GridArea: React.FC<GridAreaProps> = ({ area, children }) => {
    return <GridItem $area={area}>{children}</GridItem>;
};

// attach subcomponents & patterns to CardGrid
CardGrid.GridArea = GridArea;
CardGrid.Layout = Layout;
CardGrid.Pattern = PatternAreas;

export default CardGrid;
