import React, { useMemo } from 'react';
import { StaticImageData } from 'next/image';
import { ButtonVariants } from 'Theme/types/elements.type';

import ArrowButton from 'Elements/Button/Arrow';
import { LinkButtonProps } from 'Elements/Button';

import Grid, { Col } from 'Components/Grid';

import {
    Wrapper,
    Heading,
    Container,
    PostHeading,
    MobileNextImage,
    DesktopNextImage
} from './Homepage.styled';

export interface Props {
    imageThemeBuilder: {
        textColor?: React.CSSProperties['color'];
        searchColor?: React.CSSProperties['color'];
        buttonVariant?: ButtonVariants;
        desktop: {
            src: StaticImageData | string;
            alt: string | undefined;
        };
        mobile: {
            src: StaticImageData | string;
            alt: string | undefined;
        };
    }[];
    heading: string;
    postHeading?: string;
    button?: LinkButtonProps;
}

const Homepage: React.FC<Props> = props => {
    const { imageThemeBuilder, heading, postHeading } = props;
    const random = useMemo(
        () => Math.floor(Math.random() * props.imageThemeBuilder.length),
        [imageThemeBuilder]
    );

    return (
        <Wrapper
            style={
                {
                    '--text-color': imageThemeBuilder[random].textColor
                } as React.CSSProperties
            }
        >
            <Container>
                <DesktopNextImage
                    src={imageThemeBuilder[random].desktop.src}
                    alt={imageThemeBuilder[random].desktop.alt || ''}
                    fill
                    priority
                    loading="eager"
                    quality={100}
                    style={{ objectFit: 'cover' }}
                    className="next-image"
                />
                <MobileNextImage
                    src={imageThemeBuilder[random].mobile.src}
                    alt={imageThemeBuilder[random].desktop.alt || ''}
                    fill
                    priority
                    loading="eager"
                    quality={100}
                    style={{ objectFit: 'cover' }}
                    className="next-image"
                />
                <Grid>
                    <Col b={9} s={8} sl={7} m={6}>
                        <Heading aria-label={`${heading} ` + `${postHeading}`}>
                            {heading}
                            {postHeading && <PostHeading>{postHeading}</PostHeading>}
                        </Heading>
                    </Col>

                    <Col sl={9} m={[1, 7]} l={[1, 6]}>
                        <ArrowButton
                            {...props.button}
                            variant={
                                imageThemeBuilder[random].buttonVariant &&
                                imageThemeBuilder[random].buttonVariant
                            }
                        />
                    </Col>
                </Grid>
            </Container>
        </Wrapper>
    );
};

export default Homepage;
