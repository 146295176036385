import React, { useState } from 'react';

import { Wrapper, Container, Heading, HiddenLink, StyledIcon } from './Icon.styled';
import Arrow, { ArrowLinkProps } from 'Elements/Link/Arrow';
import { LinkVariants } from 'Theme/types/elements.type';
import { IconCardVariants } from 'Theme/types/components.type';

type Colors = {
    background?: React.CSSProperties['color'];
    backgroundHover?: React.CSSProperties['color'];
    color?: React.CSSProperties['color'];
    colorHover?: React.CSSProperties['color'];
    hoverLinkVariant?: LinkVariants;
};

export interface Props {
    icon: React.ElementType;
    heading: string;
    headingType?: 'h2' | 'h3';
    text: string;
    link: ArrowLinkProps;
    variant?: IconCardVariants;
    colors?: Colors;
}

const Icon: React.FC<Props> = ({
    heading,
    headingType = 'h2',
    text,
    link,
    colors,
    icon,
    variant
}) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Wrapper
            $isHovered={isHovered}
            $variant={variant}
            style={
                {
                    '--bg': colors?.background,
                    '--bg-hover': colors?.backgroundHover,
                    '--color': colors?.color,
                    '--color-hover': colors?.colorHover
                } as React.CSSProperties
            }
        >
            <Container>
                <StyledIcon as={icon} />
                <Heading as={headingType}>{heading}</Heading>
                <p>{text}</p>
                <Arrow
                    {...link}
                    variant={
                        isHovered ? colors?.hoverLinkVariant : link.variant ? link.variant : 'text'
                    }
                    isHovered={isHovered}
                    iconOutline={!isHovered}
                    data-hovered={isHovered}
                />
            </Container>
            <HiddenLink
                href={link.href}
                aria-label={link.ariaLabel} // here for testing
                aria-hidden="true"
                tabIndex={-1}
                data-hovered={isHovered}
                onMouseOver={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />
        </Wrapper>
    );
};

export default Icon;
