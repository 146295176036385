import React, { useState } from 'react';
import { LinkVariants } from 'Theme/types/elements.type';
import ArrowLink, { ArrowLinkProps } from 'Elements/Link/Arrow';

import { Wrapper, HiddenLink } from './Arrow.styled';

export interface Props extends ArrowLinkProps {
    colors?: {
        bgColor?: string;
        bgColorHover?: string;
        hoverLinkVariant?: LinkVariants;
    };
}

const Arrow: React.FC<Props> = ({ colors, ...link }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Wrapper
            style={
                {
                    '--bg-color': colors?.bgColor && colors.bgColor,
                    '--bg-color-hover': colors?.bgColorHover && colors.bgColorHover
                } as React.CSSProperties
            }
            $isHovered={isHovered}
        >
            <ArrowLink
                {...link}
                isHovered={isHovered}
                iconOutline={!isHovered}
                variant={isHovered ? colors?.hoverLinkVariant : link.variant}
            />
            <HiddenLink
                href={link.href}
                aria-label={link.ariaLabel}
                aria-hidden="true"
                tabIndex={-1}
                data-hovered={isHovered}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />
        </Wrapper>
    );
};

export default Arrow;
