import React from 'react';

import Grid, { Col } from 'Components/Grid';
import Arrow, { Props as ArrowLinkProps } from 'Elements/Link/Arrow';
import type { Props as SearchCourseProps } from 'Components/Search/Course';
import { useTheme } from 'styled-components';
import useBreakpoint from 'Common/hooks/useBreakpoint';

import { Wrapper, ButtonWrapper, SearchContainer, StyledSearchCourse } from './QuickLinks.styled';

export interface Props extends React.HTMLAttributes<HTMLElement> {
    link: ArrowLinkProps;
    searchCourse: Omit<SearchCourseProps, 'placeholder'>;
    colors?: {
        bgColor?: string;
    };
}

const QuickLinks: React.FC<Props> = props => {
    const { link, searchCourse, colors, ...rest } = props;
    const { colors: themeColors, breakpoints } = useTheme();
    const isLargeBreakpoint = useBreakpoint(breakpoints.large.value);

    return (
        <Wrapper
            {...rest}
            style={
                {
                    '--bg-color': colors?.bgColor && colors.bgColor
                } as React.CSSProperties
            }
        >
            <Grid>
                <Col s={12} m={[1, 9]}>
                    <SearchContainer>
                        <StyledSearchCourse
                            {...searchCourse}
                            placeholder={
                                isLargeBreakpoint
                                    ? 'Get started on your journey - Search for a course'
                                    : 'Search for a course'
                            }
                            heightType="tall"
                            themeColor={{
                                backgroundColor: themeColors.primaryContrast.hex,
                                color: themeColors.primary.hex
                            }}
                        />
                    </SearchContainer>
                </Col>
                <Col s={12} m={[9, 13]}>
                    <ButtonWrapper>
                        <Arrow {...link} iconOutline variant="secondary" />
                    </ButtonWrapper>
                </Col>
            </Grid>
        </Wrapper>
    );
};

export default QuickLinks;
