import type { Entry } from 'contensis-delivery-api';

export const searchCourseFetchResults = (
    courses: Entry[],
    value: string
): { id: string; label: string; href: string }[] => {
    const createFullCourseName = course =>
        `${course.degreeType && course.degreeType !== 'none' ? course.degreeType + ' ' : ''}${
            course.name
        }`;

    return value.trim() !== ''
        ? courses
              .filter(course => createFullCourseName(course).toLowerCase().includes(value.trim()))
              .map(course => ({
                  id: course.sys.id,
                  label: createFullCourseName(course),
                  href: course.sys.uri
              }))
        : [];
};
