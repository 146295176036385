import styled, { css } from 'styled-components';

import Arrow from 'Elements/Link/Arrow';
import NextLink from 'next/link';

export const Wrapper = styled.div`
    position: relative;
    padding-bottom: 1.4rem;
    height: 100%;
`;

export const Container = styled.div<{ $isHovered: boolean; $featured: boolean }>`
    ${({ theme, $isHovered, $featured }) => css`
        position: relative;
        border: 0.2rem solid var(--border-color, ${theme.colors.primaryContrast.hex});
        transform: translateZ(0);
        border-radius: 0.5rem;
        min-height: ${$featured ? '26rem' : '10rem'};
        height: 100%;
        overflow: hidden;

        ${$isHovered &&
        css`
            border-color: var(--border-color-hover, ${theme.colors.primary.hex});
        `}

        transition: border-color 0.25s ease, outline 0.25s ease;

        @media ${theme.breakpoints.medium.media} {
            min-height: 11.25rem;
        }

        @media ${theme.breakpoints.large.media} {
            min-height: 15.5rem;
        }
    `}
`;

export const ImageWrapper = styled.div`
    height: 100%;

    img {
        border-radius: 0.5rem;
        transform: translateZ(0);
        transition: scale 0.25s ease;
    }
`;

export const StyledArrow = styled(Arrow)<{ $isHovered: boolean }>`
    ${({ theme, $isHovered }) => css`
        position: absolute;
        z-index: 2;
        left: 50%;
        bottom: -0.3rem;
        padding: 0.25rem 0.5rem 0.75rem 1rem;
        transform: translateX(-50%);
        white-space: nowrap;

        &::after {
            content: '';
            position: absolute;
            border-radius: ${$isHovered ? '5rem' : '0.5rem'};
            background: var(--border-color, ${theme.colors.primaryContrast.hex});
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            transition: border-radius 0.25s ease, background 0.25s ease;

            ${$isHovered &&
            css`
                background: var(--border-color-hover, ${theme.colors.primary.hex});
            `}
        }
    `}
`;

export const HiddenLink = styled(NextLink)`
    position: absolute;
    inset: 0;
`;
